import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import swal from "sweetalert";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import MarketListTable from "./MarketListTable";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { dateFormatter } from "../../utils/helpers";
import { exportMartketList, exportMartketListSheet } from "./exportFuntions";
import Check from "../../other/Check";
import { IS_ACTIVE } from "../../../../constants";
import api from "../../../../helper/axiosInstance";
import Select from "react-select";
import { toastError } from "../../../../helper/messages";
import MarketListTableSheets from "./MarketListTableSheets";

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

  return str;
};
const CustomInput = ({ value, onClick }) => (
  <Button variant="primary" onClick={onClick}>
    <i className="fa-solid fa-calendar" />
  </Button>
);
const MarketList = () => {
  const [school, setSchool] = useState({});
  const [isChangeVoucherMarket, setIsChangeVoucherMarket] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [keyword, setKeyWord] = useState("");
  const [suppliers, setSuplliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [voucher, setVoucher] = useState({
    menu_date: new Date().getTime() / 1000,
  });
  const [ingredients, setIngredients] = useState([]);
  const [ingredientsConstant, setIngredientsConstant] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [dataExport, setDataExport] = useState([]);
  const [searchIngredients, setSearchIngredients] = useState([]);

  useEffect(() => {
    document.title = "Danh sách đi chợ";

    getMarketList(new Date());
    getDetailschool();
  }, []);

  //#region  API
  const getMarketList = async (date) => {
    setIsLoading(true);
    await api
      .get(`get-kitchen-ingredient-orders`, {
        params: {
          date: date.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setVoucher(res.data.data.vouchers);
        const ingredients = res.data.data.ingredients;

        setIngredients(ingredients);
        setSearchIngredients(ingredients);
        setIngredientsConstant(ingredients);

        getListSuppliers(ingredients);
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListSuppliers = async (listIngredient) => {
    await api
      .get("/supplier-show")
      .then((res) => {
        if (res.data.errCode === 0) {
          const dataSuppliers =
            res.data?.suppliers.map((supplierItem) => {
              return {
                id: supplierItem.id,
                supplier_name: supplierItem.supplier_name,
              };
            }) || [];

          setSuplliers(dataSuppliers);

          let dataa = dataSuppliers?.map((supplierItem) => {
            return {
              id: supplierItem.id,
              supplier_name: supplierItem.supplier_name,
              ingredients: listIngredient
                ?.filter(
                  (item) => Number(item.id_supplier) === Number(supplierItem.id)
                )
                ?.sort((a, b) => {
                  const typeComparison = a.is_next_day - b.is_next_day;
                  if (typeComparison !== 0) {
                    return typeComparison;
                  }
                  return a.ingredent_name.localeCompare(b.ingredent_name);
                }),
              // ?.sort((a, b) => a.ingredent_type - b.ingredent_type),
            };
          });
          setDataExport(dataa);
        }
      })
      .catch((error) => {});
  };

  const saveMarketList = async () => {
    setIsSendingForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("menu_date", voucher.menu_date);
    bodyFormData.append("ingredents", JSON.stringify(ingredientsConstant));

    await api({
      method: "post",
      url: "voucher-save",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendingForm(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          setIsChangeVoucherMarket(false);
          getMarketList(dateSelected);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const deleteVoucherMarket = (voucher) => {
    swal({
      title: "Bạn muốn xóa phiếu đi chợ",
      text: `"Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/voucher-destroy`, {
            params: {
              id: voucher.id_voucher,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });

              setIsChangeVoucherMarket(false);
              getMarketList(dateSelected);
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {});
      }
    });
  };
  // #endregion

  //#region Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportMartketList(ingredients);
    XLSX.utils.book_append_sheet(wb, ws, "Phiếu đi chợ");

    XLSX.writeFile(
      wb,
      `Phiếu đi chợ (${dateFormatter(new Date(voucher.menu_date * 1000))}).xlsx`
    );
  };

  const handleXportMarketList = () => {
    swal({
      title: `Bạn muốn xuất phiếu đi chợ`,
      text: `Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const handleXportMarketListSheets = () => {
    swal({
      title: `Bạn muốn xuất phiếu đi chợ`,
      text: `Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportMartketListSheet(dataExport, voucher, selectedSuppliers);
      }
    });
  };

  const handleSaveMarketList = () => {
    swal({
      title: `Bạn muốn lưu phiếu đi chợ`,
      text: `Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Lưu"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        saveMarketList();
      }
    });
  };

  const handleCancelMarketChange = () => {
    swal({
      title: `Bạn muốn hủy thay đổi phiếu đi chợ`,
      text: `Lưu ý: hành động không thể khôi phục`,
      icon: "info",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        setIsChangeVoucherMarket(false);
      }
    });
  };

  const handleSearchIngredient = (keyword, dataSuppliers = []) => {
    setKeyWord(keyword);

    const searchIngredients = ingredientsConstant?.filter((ingredientItem) => {
      const checkFilterByKeyword = toSlug(
        ingredientItem.ingredent_name.trim()
      ).includes(toSlug(keyword.trim()));

      const checkFilterBySupplierId =
        dataSuppliers.length > 0
          ? dataSuppliers.some(
              (itemSupplier) => itemSupplier.id === ingredientItem.id_supplier
            )
          : true;

      if (checkFilterByKeyword && checkFilterBySupplierId) return true;
    });

    setIngredients(searchIngredients);
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section>
        <Row>
          <Col lg={6} md={12} className="d-flex align-items-center">
            <strong className="me-1">Trạng thái:</strong>
            {voucher.is_voucher_saved === IS_ACTIVE.TRUE ? (
              <>
                <span className="text-success me-1">Đã lưu phiếu</span>{" "}
                <span className="fa-solid fa-check text-success fw-bold" />
              </>
            ) : (
              <span className="text-warning">Tạm tính</span>
            )}
          </Col>

          <Col lg={3} md={6} className="mb-2">
            <Form.Control
              type="text"
              placeholder="Tìm nguyên liệu"
              value={keyword}
              onChange={(e) =>
                handleSearchIngredient(e.target.value, selectedSuppliers)
              }
              style={{ minHeight: "38px" }}
            />
          </Col>

          <Col lg={3} md={6} className="mb-2" style={{ zIndex: 1001 }}>
            <Select
              options={suppliers}
              isMulti
              placeholder={<div>Tất cả nhà cung cấp</div>}
              getOptionLabel={(option) => option.supplier_name}
              getOptionValue={(option) => option.id}
              value={selectedSuppliers}
              onChange={(choice) => {
                setSelectedSuppliers(choice);

                handleSearchIngredient(keyword, choice);
              }}
            />
          </Col>
        </Row>
      </section>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Phiếu đi chợ</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                Phiếu đi chợ ngày{" "}
                {dateFormatter(new Date(voucher.menu_date * 1000))}
              </Card.Title>
              <div
                className="me-2 d-flex align-items-center"
                style={{ zIndex: "1021" }}
              >
                <DatePicker
                  disabled={isSendingForm}
                  selected={dateSelected}
                  onChange={(date) => {
                    getMarketList(date);
                    setDateSelected(date);
                    setKeyWord("");
                  }}
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                  customInput={<CustomInput />}
                  locale={vi}
                />
              </div>
            </div>

            <div className="d-flex">
              <Button
                size="sm"
                variant="success"
                onClick={() => handleXportMarketListSheets()}
                className="me-2"
                disabled={isLoading || isSendingForm}
              >
                <i className="fa-solid fa-file-export" /> Xuất file theo NCC
              </Button>
              <Button
                size="sm"
                variant="success"
                onClick={() => handleXportMarketList()}
                className="me-2"
                disabled={isLoading || isSendingForm}
              >
                <i className="fa-solid fa-file-export" /> Xuất file
              </Button>
              {voucher.is_voucher_saved === IS_ACTIVE.TRUE &&
              new Check().permission([118]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => deleteVoucherMarket(voucher)}
                  className="me-2"
                  disabled={isLoading || isSendingForm}
                >
                  <i className="fa-solid fa-times" /> Hủy phiếu đi chợ
                </Button>
              ) : (
                <></>
              )}

              {voucher.is_voucher_saved === IS_ACTIVE.FALSE &&
              new Check().permission([117]) ? (
                <>
                  <Button
                    variant={isChangeVoucherMarket ? "danger" : "success"}
                    size="sm"
                    onClick={() => {
                      if (isChangeVoucherMarket) {
                        handleCancelMarketChange();
                      } else {
                        setIsChangeVoucherMarket(true);
                      }
                    }}
                    disabled={isLoading || isSendingForm}
                  >
                    {isChangeVoucherMarket ? (
                      <>
                        <i className="fa-solid fa-times" /> Hủy thay đổi
                      </>
                    ) : (
                      <>
                        <i className="fa-solid fa-exchange" /> Thay đổi phiếu đi
                        chợ
                      </>
                    )}
                  </Button>

                  {isChangeVoucherMarket ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleSaveMarketList(true)}
                      className="ms-2"
                      disabled={isLoading || isSendingForm}
                    >
                      <i className="fa-solid fa-check" /> Lưu phiếu đi chợ
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              {RenderOption()}

              <MarketListTable
                voucher={voucher}
                ingredients={ingredients}
                setIngredients={setIngredients}
                ingredientsConstant={ingredientsConstant}
                setIngredientsConstant={setIngredientsConstant}
                school={school}
                suppliers={suppliers}
                isChangeVoucherMarket={isChangeVoucherMarket}
              />

              <MarketListTableSheets
                voucher={voucher}
                ingredients={dataExport}
              />
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MarketList;
