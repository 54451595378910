import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { priceFormatterNoDecimal } from "../utils/helpers";
import CurrencyFormat from "react-currency-format";
import Check from "../other/Check";
import Select from "react-select";
import api from "../../../helper/axiosInstance";

class BillEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.datarole },
      filterText: "",
      students: [],
      studentsSearch: [],
      isTableSelectable: true,
      ids: [],
      err: {},
      tuition: [],
      paymentMethod: [],
    };
  }

  componentDidMount() {
    this.getTuition();
    this.getPaymentMethod();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.updateBill();
  };
  getTuition = () => {
    api
      .get("/tuition-shows")
      .then((res) => {
        this.setState({
          tuition: res.data.data || [],
        });
      })
      .catch((err) => {});
  };
  getPaymentMethod = () => {
    api
      .get("/payment-method")
      .then((res) => {
        this.setState({
          paymentMethod: res.data.data || [],
        });
      })
      .catch((err) => {});
  };
  updateBill = () => {
    let data = {
      id: this.state.dataInput?.id, // required
      id_student: this.state.dataInput.student?.id, // required
      order_id: this.state.dataInput.order_id, // required, unique
      total_money: parseFloat(
        this.state.dataInput?.details?.reduce((acc, e) => {
          const quantity = parseFloat(e.quantity || 0);
          const price = parseFloat(e.price || 0);
          const itemTotal = quantity * price;

          if (
            e.bill_detailable === "NotEatRefunded" ||
            e.bill_detailable === "HolidayRefunded" ||
            e.bill_detailable === "FoodRefunded" ||
            e.bill_detailable === "DiscountRefunded"
          ) {
            return acc - itemTotal; // Trừ đi nếu là loại Refund
          } else {
            return acc + itemTotal; // Cộng vào nếu không phải Refund
          }
        }, 0) || ""
      ), // required, integer lte 999999999, k update
      trans_amount: parseFloat(
        this.state.dataInput?.details?.reduce((acc, e) => {
          const quantity = parseFloat(e.quantity || 0);
          const price = parseFloat(e.price || 0);
          const itemTotal = quantity * price;

          if (
            e.bill_detailable === "HolidayRefunded" ||
            e.bill_detailable === "FoodRefunded" ||
            e.bill_detailable === "NotEatRefunded" ||
            e.bill_detailable === "DiscountRefunded"
          ) {
            return acc - itemTotal; // Trừ đi nếu là loại Refund
          } else {
            return acc + itemTotal; // Cộng vào nếu không phải Refund
          }
        }, 0) || ""
      ), // required, integer lte 999999999
      tax: this.state.dataInput.tax, // required 1 - 100
      provider: this.state.dataInput.provider || "",
      bill_notes: this.state.dataInput?.bill_notes || "",
      transaction_date: this.state.dataInput.transaction_date || "",
      pay_status: this.state.dataInput.pay_status == 1 ? "PAID_OFF" : "UNPAID", // required, only accept: PAID_OFF, UNPAID
      details: this.state.dataInput.details?.map((e) => {
        return {
          // ...e,
          id_bill_detailable: e.id_bill_detailable,
          bill_detailable: e.bill_detailable,
          content: e.content,
          price: parseFloat(e.price),
          quantity: parseFloat(e.quantity),
          sub_total: parseFloat(e.price * e.quantity),
        };
      }),
    };

    api
      .put("bill-update", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập nhật thành công", { type: "success" });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };
  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }

  handleHide = () => {
    return this.props.onHide();
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    const paymentOptions = this.state.paymentMethod?.map((payment) => ({
      value: payment.provider,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {payment.logo && (
            <img
              src={payment.logo}
              alt={payment.provider}
              style={{ marginRight: "5px", width: "20%" }}
            />
          )}
          {payment.subName}
        </div>
      ),
    }));
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {other.modal.title} tháng{" "}
              {new Date(this.state.dataInput?.bill_date * 1000).getMonth() + 1}/
              {new Date(this.state.dataInput?.bill_date * 1000).getFullYear()}
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col xs={6} md={3}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="role_name"
                      defaultValue={
                        this.state.dataInput?.student?.student_name || ""
                      }
                      placeholder="Tên chức vụ"
                      disabled
                    />
                    <Form.Label>
                      Tên học sinh<i className="text-danger"></i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
                <Col xs={6} md={3}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="role_name"
                      defaultValue={this.state.dataInput?.order_id || ""}
                      placeholder="Tên chức vụ"
                      disabled
                    />
                    <Form.Label>
                      Mã phiếu thu<i className="text-danger"></i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
                <Col xs={6} md={3}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="role_name"
                      value={priceFormatterNoDecimal.format(
                        parseFloat(
                          this.state.dataInput?.details?.reduce((acc, e) => {
                            const quantity = parseFloat(e.quantity || 0);
                            const price = parseFloat(e.price || 0);
                            const itemTotal = quantity * price;

                            if (
                              e.bill_detailable === "HolidayRefunded" ||
                              e.bill_detailable === "NotEatRefunded" ||
                              e.bill_detailable === "FoodRefunded" ||
                              e.bill_detailable === "DiscountRefunded"
                            ) {
                              return acc - itemTotal; // Trừ đi nếu là loại Refund
                            } else {
                              return acc + itemTotal; // Cộng vào nếu không phải Refund
                            }
                          }, 0) || 0
                        ) || 0
                      )}
                      placeholder="Tên chức vụ"
                      className="text-end"
                      disabled
                    />
                    <Form.Label>
                      Tổng tiền (đ)<i className="text-danger"></i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
                <Col xs={6} md={3}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.dataInput?.pay_status}
                      onChange={(event) => {
                        let data = { ...this.state.dataInput };
                        data.pay_status = event.target.value;
                        this.setState({ dataInput: data });
                      }}
                      disabled={this.props.datarole?.pay_status == 1}
                      name="student_gender"
                      required
                    >
                      <option value="">--Chọn--</option>
                      <option value="1">Đã thanh toán</option>
                      <option value="0">Chưa thanh toán</option>
                    </Form.Select>
                    <Form.Label>
                      Trạng thái
                      <i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
              </Row>

              <Row
                className={
                  this.state.dataInput.pay_status == 0 ||
                  this.state.dataInput.pay_status == ""
                    ? "d-none"
                    : ""
                }
              >
                {/* <Col>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.dataInput?.provider}
                      onChange={(event) => {
                        let data = { ...this.state.dataInput };
                        data.provider = event.target.value;
                        this.setState({ dataInput: data });
                        delete this.state.err[`provider`];
                      }}
                      disabled={this.props.datarole?.pay_status == 1}
                      name="provider"
                      required={this.state.dataInput.pay_status}
                    >
                      <option value="">--Chọn--</option>

                      {this.state.paymentMethod?.map((payment) => {
                        return (
                          <option value={payment.provider}>
                            <div>
                              <img
                                src="https://api.vietqr.io/img/HDB.png"
                                alt={payment.provider}
                              />
                              {payment.subName}
                            </div>
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Label>
                      Nguồn tiền
                      <i className="text-danger">
                        {this.state.dataInput.pay_status == 1 ? "*" : ""}
                      </i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.provider}
                    </span>
                  </Form.Floating>
                </Col> */}
                <Col xs={6}>
                  <Select
                    // className="form-control"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: "50px",
                      }),
                    }}
                    value={paymentOptions.find(
                      (option) => option.value == this.state.dataInput?.provider
                    )}
                    onChange={(selectedOption) => {
                      let data = { ...this.state.dataInput };
                      data.provider = selectedOption.value;
                      this.setState({ dataInput: data });
                      delete this.state.err[`provider`];
                    }}
                    isDisabled={this.props.datarole?.pay_status == 1}
                    name="provider"
                    placeholder={
                      <div>
                        Chọn nguồn tiền <i className="text-danger">*</i>
                      </div>
                    }
                    // "Chọn nguồn tiền *"
                    options={paymentOptions}
                    isRequired={this.state.dataInput.pay_status}
                  />
                </Col>

                <Col xs={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required={this.state.dataInput.pay_status}
                      disabled={this.props.datarole?.pay_status == 1}
                      value={moment(
                        this.state.dataInput?.transaction_date
                      ).format("YYYY-MM-DD")}
                      name="start_date"
                      onChange={(e) => {
                        if (e.target.value) {
                          let data = { ...this.state.dataInput };
                          data.transaction_date = new Date(
                            e.target.value
                          )?.toISOString();
                          this.setState({ dataInput: data });
                        }

                        delete this.state.err[`transaction_date`];
                      }}
                    />
                    <Form.Label>
                      Ngày thanh toán
                      <i className="text-danger">
                        {this.state.dataInput.pay_status == 1 ? "*" : ""}
                      </i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`transaction_date`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      id="selectTeacherId"
                      name="teachers"
                      placeholder="Khoản thu"
                      value={0}
                      disabled={this.props.datarole?.pay_status == 1}
                      onChange={(e) => {
                        const idTuition = Number(e.target.value);
                        let newDataInput = { ...this.state.dataInput };

                        const tuitionChose = this.state.tuition?.filter(
                          (item) => item.id == idTuition
                        );
                        let amount = 0;
                        if (tuitionChose[0]?.discounts?.length < 1) {
                          amount = tuitionChose[0]?.amount;
                        } else {
                          let amount1 = 0;
                          amount = tuitionChose[0]?.discounts?.reduce(
                            (total, discount) => {
                              return discount.type === "fixed"
                                ? total + discount.value
                                : total +
                                    (tuitionChose[0]?.amount * discount.value) /
                                      100;
                            },
                            0
                          );
                        }
                        newDataInput.details.push({
                          price: parseFloat(
                            tuitionChose[0]?.amount - amount || 0
                          ),
                          content: tuitionChose[0]?.name,
                          quantity: 1,
                          id_bill_detailable: tuitionChose[0]?.id,
                          bill_detailable: "",
                          sub_total: "",
                        });

                        this.setState({
                          dataInput: newDataInput,
                        });
                      }}
                    >
                      <option value="">--Chọn--</option>

                      {this.state?.tuition?.map((tuition) => {
                        const checkIsset = this.state.dataInput.details
                          ? this.state.dataInput.details.some(
                              (item) => item.content == tuition.name
                            )
                          : false;

                        // if (!checkIsset)
                        return (
                          <option key={tuition.id} value={tuition.id}>
                            {tuition.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Label for="selectTeacherId">Khoản thu</Form.Label>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      placeholder="giảm giá"
                      value={0}
                      disabled={this.props.datarole?.pay_status == 1}
                      onChange={(e) => {
                        const idTuition = Number(e.target.value);
                        let newDataInput = { ...this.state.dataInput };

                        const tuitionChose = this.props.discounts?.filter(
                          (item) => item.value == idTuition
                        );
                        let amount = 0;

                        if (tuitionChose[0]?.type === "fixed") {
                          amount = tuitionChose[0]?.amount;
                        } else {
                          amount =
                            this.state.dataInput?.details?.reduce((acc, e) => {
                              const quantity = parseFloat(e.quantity || 0);
                              const price = parseFloat(e.price || 0);
                              const itemTotal = quantity * price;

                              if (
                                e.bill_detailable === "HolidayRefunded" ||
                                e.bill_detailable === "FoodRefunded" ||
                                e.bill_detailable === "NotEatRefunded" ||
                                e.bill_detailable === "DiscountRefunded"
                              ) {
                                return acc - itemTotal; // Trừ đi nếu là loại Refund
                              } else {
                                return acc + itemTotal; // Cộng vào nếu không phải Refund
                              }
                            }, 0) *
                            (tuitionChose[0]?.amount / 100);
                        }
                        newDataInput.details.push({
                          price: parseFloat(amount || 0)?.toFixed(0),
                          content: tuitionChose[0]?.name,
                          quantity: 1,
                          id_bill_detailable: tuitionChose[0]?.id,
                          bill_detailable: "DiscountRefunded",
                          sub_total: "",
                        });

                        this.setState({
                          dataInput: newDataInput,
                        });
                      }}
                    >
                      <option value="">--Chọn--</option>
                      {this.props?.discounts?.map((tuition) => {
                        const checkIsset = this.state.dataInput.details
                          ? this.state.dataInput.details.some(
                              (item) => item.content == tuition.name
                            )
                          : false;

                        // if (!checkIsset)
                        return (
                          <option key={tuition.value} value={tuition.value}>
                            {tuition.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Label for="selectTeacherId">Giảm giá</Form.Label>
                  </Form.Floating>
                </Col>
              </Row>
              <div style={{ overflow: "auto" }}>
                <Table bordered style={{ borderColor: "#000" }}>
                  <thead>
                    <tr className="text-center">
                      <th>STT</th>
                      <th style={{ minWidth: "150px" }}>Khoản thu</th>
                      <th style={{ minWidth: "150px" }}>Loại</th>
                      <th style={{ minWidth: "100px" }}>Số lượng</th>
                      <th style={{ minWidth: "100px" }}>Giá (đ)</th>
                      <th style={{ minWidth: "100px" }}>Thành tiền (đ)</th>
                      {new Check().permission(["131"]) ? <th></th> : <></>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dataInput?.details
                      ?.filter((e) => e.quantity > 0)
                      ?.map((e, i) => (
                        <tr key={i} className="align-middle">
                          <td className="text-center">{i + 1}</td>
                          <td>
                            <Form.Control
                              type="text"
                              name="role_name"
                              disabled={this.props.datarole?.pay_status == 1}
                              value={e.content}
                              placeholder="Khoản thu"
                              onChange={(event) => {
                                let data = { ...this.state.dataInput };
                                data.details[i].content = event.target.value;
                                this.setState({ dataInput: data });
                                delete this.state.err[`details[${i}].content`];
                              }}
                            />
                            <span className="text-form-err">
                              {this.state.err[`details[${i}].content`]}
                            </span>
                          </td>
                          <td>
                            <Form.Select
                              value={e?.bill_detailable}
                              disabled={this.props.datarole?.pay_status == 1}
                              onChange={(event) => {
                                let data = { ...this.state.dataInput };
                                data.details[i].bill_detailable =
                                  event.target.value;
                                this.setState({ dataInput: data });
                                delete this.state.err[
                                  `details[${i}].bill_detailable`
                                ];
                              }}
                              name="bill_detailable"
                              required
                            >
                              <option value="">--Chọn--</option>
                              <option value="MonthlyTuition">
                                Thu theo lớp
                              </option>
                              <option value="ExtraTuition">
                                Thu theo môn học thêm
                              </option>
                              <option value="AnnualTuition">
                                Thu theo năm
                              </option>
                              <option value="FoodMoney">Tiền ăn</option>
                              <option value="FoodRefunded">Hoàn tiền ăn</option>
                              <option value="NotEatRefunded">
                                Hoàn không ăn tại trường
                              </option>
                              <option value="HolidayRefunded">
                                Hoàn nghỉ lễ
                              </option>
                              <option value="DiscountRefunded">Giảm giá</option>
                              {/* <option value="Other">Khác</option> */}
                            </Form.Select>
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              disabled={this.props.datarole?.pay_status == 1}
                              name="role_name"
                              defaultValue={e.quantity}
                              placeholder="Số lượng"
                              className="text-end"
                              onChange={(event) => {
                                let data = { ...this.state.dataInput };
                                data.details[i].quantity = event.target.value;
                                this.setState({ dataInput: data });
                                delete this.state.err[`details[${i}].quantity`];
                              }}
                              required
                            />
                            <span className="text-form-err">
                              {this.state.err[`details[${i}].quantity`]}
                            </span>
                          </td>
                          <td className="text-end">
                            {/* <Form.Control
                          type="number"
                          name="role_name"
                          defaultValue={e.price}
                          placeholder="Giá"
                          className="text-end"
                          onChange={(event) => {
                            let data = { ...this.state.dataInput };
                            data.details[i].price = event.target.value;
                            this.setState({ dataInput: data });
                            delete this.state.err[`details[${i}].price`];
                          }}
                          required
                        /> */}

                            <CurrencyFormat
                              className="form-control text-end"
                              thousandSeparator={true}
                              placeholder="Giá"
                              disabled={this.props.datarole?.pay_status == 1}
                              required
                              allowNegative={false}
                              decimalScale={0}
                              // suffix=" VNĐ"
                              value={e.price}
                              onValueChange={(values) => {
                                const { value } = values;
                                let data = { ...this.state.dataInput };
                                data.details[i].price = value;
                                this.setState({ dataInput: data });
                                delete this.state.err[`details[${i}].price`];
                              }}
                            />
                            <span className="text-form-err">
                              {this.state.err[`details[${i}].price`]}
                            </span>
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              // value={e.quantity * e.price}

                              value={
                                priceFormatterNoDecimal.format(
                                  e.bill_detailable === "HolidayRefunded" ||
                                    e.bill_detailable === "NotEatRefunded" ||
                                    e.bill_detailable === "FoodRefunded" ||
                                    e.bill_detailable === "DiscountRefunded"
                                    ? 0 -
                                        parseFloat(
                                          parseFloat(e.quantity || 0) *
                                            parseFloat(e.price || 0) || 0
                                        )
                                    : parseFloat(
                                        parseFloat(e.quantity || 0) *
                                          parseFloat(e.price || 0) || 0
                                      ) ||
                                        0 ||
                                        0
                                ) || 0
                              }
                              placeholder="Thành tiền"
                              className="text-end"
                              disabled
                            />
                          </td>
                          {new Check().permission(["131"]) ? (
                            <td>
                              <Button
                                size="sm"
                                variant="outline-danger"
                                title="Xóa"
                                disabled={this.props.datarole?.pay_status == 1}
                                onClick={() => {
                                  let newDataInput = {
                                    ...this.state.dataInput,
                                  };
                                  let newDetail = newDataInput?.details?.filter(
                                    (tuition) => tuition != e
                                  );
                                  newDataInput.details = newDetail;

                                  this.setState({ dataInput: newDataInput });
                                }}
                              >
                                <i className="fa-solid fa-times" />
                              </Button>
                            </td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={5} className="text-end">
                        Tổng tiền
                      </th>
                      <th className="text-end">
                        {priceFormatterNoDecimal.format(
                          parseFloat(
                            this.state.dataInput?.details?.reduce((acc, e) => {
                              const quantity = parseFloat(e.quantity || 0);
                              const price = parseFloat(e.price || 0);
                              const itemTotal = quantity * price;

                              if (
                                e.bill_detailable === "HolidayRefunded" ||
                                e.bill_detailable === "NotEatRefunded" ||
                                e.bill_detailable === "FoodRefunded" ||
                                e.bill_detailable === "DiscountRefunded"
                              ) {
                                return acc - itemTotal; // Trừ đi nếu là loại Refund
                              } else {
                                return acc + itemTotal; // Cộng vào nếu không phải Refund
                              }
                            }, 0) || 0
                          ) || 0
                        )}
                      </th>
                      {new Check().permission(["131"]) ? <th></th> : <></>}
                    </tr>
                  </tfoot>
                </Table>
              </div>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Ghi chú"
                      value={this.state.dataInput?.bill_notes || ""}
                      name="bill_notes"
                      onChange={(e) => {
                        let data = { ...this.state.dataInput };
                        data.bill_notes = e.target.value;

                        this.setState({ dataInput: data });

                        delete this.state.err[`bill_notes`];
                      }}
                    />
                    <Form.Label>Ghi chú</Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`bill_notes`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {new Check().permission(["131"]) ? (
                <Button
                  size="sm"
                  variant="success"
                  type="submit"
                  disabled={this.props.datarole?.pay_status == 1}
                >
                  <i className="fa-solid fa-check" /> Cập nhật
                </Button>
              ) : (
                <></>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default BillEditModal;
