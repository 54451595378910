import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);
const AttendanceReport = ({ data }) => {
  const labels = data?.map((item) =>
    new Date(item.date * 1000).toLocaleDateString("vi-VN")
  );

  const totalStudents = data?.map((item) =>
    item?.class?.reduce((sum, cls) => sum + cls.countStudent, 0)
  );

  const totalOffStudents = data.map((item) =>
    item?.class?.reduce((sum, cls) => sum + cls.countStudentOff, 0)
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: "Số học sinh đi học",
        data: totalStudents.map(
          (total, index) => total - totalOffStudents[index]
        ),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Số học sinh nghỉ học",
        data: totalOffStudents,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },

      // title: {
      //   display: true,
      //   text: `Tổng hợp số lượng có mặt/ nghỉ của học sinh trong tuần từ ${new Date(
      //     data[0]?.date * 1000
      //   ).toLocaleDateString("vi-VN")} đến ${new Date(
      //     data[data?.length - 1]?.date * 1000
      //   ).toLocaleDateString("vi-VN")}`,
      // },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: false,
          text: "Ngày",
        },
      },
      y: {
        grid: {
          display: true,
        },
        title: {
          display: false,
          text: "Số lượng học sinh",
        },
      },
    },
  };

  return (
    <div
      style={{
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        width: "100%",
        margin: "0 auto",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2>{`Tổng hợp số lượng có mặt/ nghỉ của học sinh trong tuần từ ${new Date(
        data[0]?.date * 1000
      ).toLocaleDateString("vi-VN")} đến ${new Date(
        data[data?.length - 1]?.date * 1000
      ).toLocaleDateString("vi-VN")}`}</h2>
      <div className="chart-attemdance-report">
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default AttendanceReport;
