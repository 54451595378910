import React, { Component } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import AttendanceReportChart from "./AttendanceReportChart";
import DailyMenuTable from "./DailyMenuTable";
import IngredientTable from "./IngredientTable";
import Countdown from "./CountDown";
import CountStudent from "./CountStudent";
import CountUser from "./CountUser";
import CountBill from "./CountBill";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      school: {},
      attendances: [],
      todaymenu: {},
      isLoadingMenu: false,
      voucherMarket: [],
    };
  }

  async componentDidMount() {
    document.title = "Tổng quan";

    const date = new Date();
    const tmpDate = new Date();
    const week = this.dates(tmpDate);

    let todayIs = date.getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    this.getAttendance(week[0], week[week.length - 1]);
    this.getMenuData();
    this.getVoucherMarK(week[0], week[week.length - 1], todayIs);
  }

  dates = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };

  getAttendance(start, end) {
    api
      .get(
        `/report-class?start_date=${parseInt(start / 1000)}&end_date=${parseInt(
          end / 1000
        )}`
      )
      .then((res) => {
        this.setState({
          attendances: res.data.data?.filter((e) => e.is_day === "study"),
        });
      })
      .catch((error) => {});
  }

  getMenuData() {
    const date = new Date();
    const tmpDate = new Date();
    const week = this.dates(tmpDate);
    this.setState({
      isLoadingMenu: true,
    });
    api
      .get(
        `/menu-show?start_at=${week[0].getTime() / 1000}&term_at=${
          week[week.length - 1].getTime() / 1000
        }`
      )
      .then((res) => {
        if (res.data.errCode === 0) {
          let todayIs = date.getDay();

          if (todayIs === 0) {
            todayIs = 7;
          }

          let tmenu = [];

          tmenu = res.data.days?.filter(
            (dayItem) => Number(dayItem?.id_day) === todayIs
          )[0];
          this.setState({
            todaymenu: tmenu,
          });
        }

        this.setState({
          isLoadingMenu: false,
        });
      })
      .catch((error) => {});
  }
  getVoucherMarK = async (startDay, endDay, dayId) => {
    this.setState({
      isLoadingVoucherMar: true,
    });

    await api
      .get(
        `/voucher-show-week?type=1&start_at=${
          startDay.getTime() / 1000
        }&term_at=${endDay.getTime() / 1000}&id_day=${dayId}`
      )
      .then((res) => {
        this.setState({
          isLoadingVoucherMar: false,
        });

        this.setState({
          voucherMarket:
            res.data.days?.filter((e) => e.id_day === dayId)[0]?.ingredents ||
            [],
        });
      })
      .catch((e) => {});
  };

  renderschool() {
    return (
      <>
        <Card.Header>
          <Card.Title>Tổng quan</Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="mb-2">
            <Col md={4}>
              <CountStudent />
            </Col>
            <Col md={4}>
              <CountUser />
            </Col>
            <Col md={4}>
              <Countdown exp_date={this.state.school?.exp_date} />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <AttendanceReportChart data={this.state.attendances || []} />
            </Col>
            <Col md={4}>
              <CountBill />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Arial, sans-serif",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h2>
                  Danh sách thực đơn: {new Date().toLocaleDateString("vi-VN")}
                </h2>
                {!this.state.isLoadingMenu ? (
                  this.state.todaymenu ? (
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        overflowX: "auto",
                      }}
                    >
                      <DailyMenuTable
                        times={this.state.todaymenu?.times || []}
                      />
                    </div>
                  ) : (
                    <div className="text-center mt-4">Không có dữ liệu</div>
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">
                        Đang tải thực đơn trong ngày...
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Col>

            <Col md={6}>
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Arial, sans-serif",
                  padding: "10px",
                  // margin: "10px 0",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h2>
                  Danh sách đi chợ dự kiến:{" "}
                  {new Date().toLocaleDateString("vi-VN")}
                </h2>
                {!this.state.isLoadingVoucherMar ? (
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {this.state.voucherMarket?.length > 0 ? (
                      <IngredientTable
                        ingredients={this.state.voucherMarket || []}
                      />
                    ) : (
                      <div className="text-center mt-4">Không có dữ liệu</div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">
                        Đang tải danh sách đi chợ...
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <Container fluid className="px-3">
        <Row>
          <Col md={12}>
            <Card className="mb-4 mt-2">{this.renderschool()}</Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Dashboard;
