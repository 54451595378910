import React, { Component } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button, Modal, Table } from "react-bootstrap";
import Check from "../../components/admin/other/Check";
import Logo from "../../assets/admin/assets/img/logo_kafood_4.png";
import moment from "moment";
import NotificationBell from "./NotificationBell";
import api from "../../helper/axiosInstance";
import UserEditModal from "../../components/admin2/user/UserEditModal";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // listUser: [],
      // user: {
      //   id_user: "",
      //   user_identifier: "",
      //   user_name: "",
      //   user_gender: "",
      //   user_birthday: "",
      //   user_ethnic: "",
      //   user_phone: "",
      //   user_contract: "",
      //   user_diploma: "",
      //   user_account: "",
      //   password: "",
      //   id_role: localStorage.getItem("role_rank"),
      //   is_meal: 0,
      // },
      // listEthnic: [],
      showModalUser: false,
      listrole: [
        {
          id: localStorage.getItem("role_id"),
          role_name: localStorage.getItem("role_name"),
        },
      ],
      showAlarm: false,
      ingredent_alarmlist: [],
      count: 0,
      showNotification: false,
      bgAvatar: "#72BF78",
    };
  }

  async componentDidMount() {
    this.domainTransfer();
    this.getBackgroundColor();
    // this.getAllUser();
    // this.getlistrole();
    // this.getIngredent();
    // this.getEthnic();
  }

  domainTransfer = () => {
    const DOMAIN = window.location.hostname;

    if (DOMAIN === "food.kaviet.vn") {
      const userConfirmed = window.confirm(
        "Thông báo! Bắt đầu từ ngày 04/09/2024 Công ty Cổ phần Công nghệ Katec đã chuyển link đăng nhập phần mềm Kafood sang tên miền mới quanly.kafood.vn, tài khoản đăng nhập và dữ liệu của quý khách hàng vẫn được giữ nguyên. Mọi chi tiết xin liên hệ 0932927007 (anh Lũy PGĐ) hoặc 0919646787 (anh Bình TP.KD). Trân Trọng!"
      );

      if (userConfirmed) {
        return (window.location.href = "https://quanly.kafood.vn");
      } else {
        return (window.location.href = "https://quanly.kafood.vn");
      }
    }
  };

  handleClose = () => {
    this.setState({ show: false, showAlarm: false });
  };

  // getAllUser = () => {
  //   api
  //     .get("/showAllUser")
  //     .then((res) => {
  //       if (res.data?.status) {
  //         this.setState({
  //           listUser: res.data.data,
  //           user: res.data.data.filter(
  //             (e) =>
  //               Number(e.id) === Number(localStorage.getItem("auth_id_user"))
  //           )[0],
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // getEthnic = () => {
  //   api.get("/viewEthnic").then((res) => {
  //     this.setState({
  //       listEthnic: res.data?.data || [],
  //     });
  //   });
  // };

  sidenavToggle = (event) => {
    document
      .getElementById("sb-nav-fixed")
      .classList.toggle("sb-sidenav-toggled");
  };

  dateNotTime(data) {
    let date = new Date(data * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  }

  logout = () => {
    swal({
      title: "Bạn muốn đăng xuất?",
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post("/logout")
          .then(
            (res) => {
              toast(res.data.message, { type: "success" });

              localStorage.removeItem("auth_id_shool");
              localStorage.removeItem("auth_id_user");
              // localStorage.removeItem("auth_name");
              localStorage.removeItem("permission");
              localStorage.removeItem("access_token");
              localStorage.removeItem("version");

              this.props.navHook("/login");
            },
            [this.navigate]
          )
          .catch((error) => {
            toast(error.response.data?.messages || "Đăng xuất thất bại!", {
              type: "error",
            });
          });
      }
    });
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  updateuser = (e) => {
    e.preventDefault();

    if (this.state.user?.user_identifier.length > 35) {
      toast("Mã định danh không được nhiều hơn 35 kí tự", {
        type: "error",
        autoClose: 2000,
      });

      return;
    }

    if (
      Number(
        moment(moment(this.state.user?.user_birthday * 1000), "YYYY")
          .fromNow()
          .split(" ")[0]
      ) < 18
    ) {
      toast("Sai ngày sinh không hợp lệ", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }

    if (this.state.user?.user_phone !== "") {
      if (
        !this.state.user?.user_phone.match(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/
        )
      ) {
        toast("Sai định dạng điện thoại", {
          type: "error",
          autoClose: 1000,
        });
        return;
      }
    }

    swal({
      title: "Bạn muốn cập nhật tài khoản?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`/updateUser`, null, {
            params: {
              id_user: localStorage.getItem("auth_id_user"),
              user_identifier: this.state.user?.user_identifier || "",
              user_name: this.state.user?.user_name || "",
              user_account: this.state.user?.user_account || "",
              user_password: this.state.user?.password || "",
              user_gender: this.state.user?.user_gender || "",
              user_birthday: this.state.user?.user_birthday,
              user_ethnic: this.state.user?.user_ethnic,
              user_phone: this.state.user?.user_phone,
              user_contract: this.state.user?.user_contract || "",
              user_diploma: this.state.user?.user_diploma || "",
              id_role: this.state.user?.id_role || "",
              is_meal: this.state.user?.is_meal || 0,
            },
          })
          .then((res) => {
            if (res.data.status) {
              this.handleClose();
              this.getAllUser();
              localStorage.setItem("auth_name", this.state.user?.user_name);
              this.setState({ id_user: "" });
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast(`${res.data.data.mess}`, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  // getIngredent = () => {
  //   api
  //     .get("/viewInventoryAll")
  //     .then((res) => {
  //       this.setState({
  //         ingredent_alarmlist: res.data.data.filter(
  //           (item) =>
  //             (item.expriry_date >
  //               new Date(new Date().setHours(0, 0, 0)) / 1000 &&
  //               new Date(new Date().setHours(0, 0, 0)) / 1000) +
  //               parseInt(item.ingredent_alarm) * 86400 >=
  //             item.expriry_date
  //         ),
  //         count: res.data.data.filter(
  //           (item) =>
  //             (item.expriry_date >
  //               new Date(new Date().setHours(0, 0, 0)) / 1000 &&
  //               new Date(new Date().setHours(0, 0, 0)) / 1000) +
  //               parseInt(item.ingredent_alarm) * 86400 >=
  //             item.expriry_date
  //         ).length,
  //       });
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };

  // getlistrole = () => {
  //   api
  //     .post("/viewRole")
  //     .then((res) => {
  //       if (res.data?.status) {
  //         this.setState({
  //           listrole: res.data.data.filter(
  //             (e) => e.is_rank >= Number(localStorage.getItem("role_rank"))
  //           ),
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  handleShowAlarm = () => {
    this.getIngredent();
    if (this.state.count > 0) this.setState({ showAlarm: true });
  };

  handleUpdate = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (event.target.name === "is_meal") {
      if (event.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    let user = { ...this.state.user };
    user[field] = value;
    this.setState({ user: user });
  };

  updateToken = () => {
    api.defaults.headers["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");
  };

  getInitials = (name) => {
    if (name) {
      let words = name.trim().split(" ");

      if (words.length === 1) {
        return words[0].charAt(0).toUpperCase();
      }

      let lastTwoWords = words.slice(-1);
      let initials =
        lastTwoWords?.map((word) => word.charAt(0).toUpperCase()).join("") ||
        "";
      return initials;
    } else {
      return "N";
    }
  };

  getBackgroundColor = () => {
    const colors = [
      "#FF885B",
      "#72BF78",
      "#FF5BAE",
      "#FF8A8A",
      "#A02334",
      "#7C00FE",
      "#36C2CE",
    ];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    this.setState({ bgAvatar: randomColor });
  };

  render() {
    if (!localStorage.getItem("access_token")) {
      this.props.navHook("/login");
    } else {
      this.updateToken();
    }

    return (
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-primary navbar-pc">
        <Modal
          show={this.state.showAlarm}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hàng sắp hết hạn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ width: "60px" }}> STT</th>
                  <th style={{ width: "170px" }}> Tên nguyên liệu</th>
                  <th style={{ width: "120px" }}> Lô</th>
                  <th style={{ width: "120px" }}> Ngày hết hạn</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "36px" }}></tr>
                {this.state.ingredent_alarmlist?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ width: "60px" }}>{index + 1}</td>
                      <td style={{ width: "170px" }}> {item.ingredent_name}</td>
                      <td style={{ width: "120px" }}>{item.code_inventory}</td>
                      <td style={{ width: "120px" }}>
                        {this.dateNotTime(item.expriry_date)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ showAlarm: false });
                this.props.navHook("/admin-v2/store");
              }}
            >
              {" "}
              Tới kho hàng
            </Button>
          </Modal.Footer>
        </Modal>

        <Link
          className="navbar-brand ps-3 pe-1 d-flex"
          to={"/admin-v2/dashboard"}
        >
          <img src={Logo} style={{ width: 69, height: 17 }} alt="Kafood" />
        </Link>

        <Button
          variant="outline-light"
          size="sm"
          className="order-1 order-lg-0 me-2"
          id="sidebarToggle"
          onClick={this.sidenavToggle}
        >
          <i className="fas fa-bars"></i>
        </Button>

        <div className="menu-navbar">
          <NotificationBell />

          <div className="d-flex justify-content-between align-items-center">
            {new Check().permission(["89"]) ? (
              <i
                className="fa-solid fa-hourglass-1 fa-lg me-2 pt-1"
                style={{
                  color: "#ffffff",
                  cursor: this.state.count > 0 ? "pointer" : null,
                }}
                onClick={this.handleShowAlarm}
              >
                {this.state.count > 0 ? <sup>{this.state.count}</sup> : null}{" "}
              </i>
            ) : null}{" "}
          </div>

          <ul className="navbar-nav ms-auto ms-md-0 me-1 me-lg-4">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle text-light d-flex align-items-center"
                id="navbarDropdown"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="limited-text">
                  {localStorage.getItem("auth_name")}
                </span>

                <span
                  className="img-cricle"
                  style={{ backgroundColor: this.state.bgAvatar }}
                >
                  {this.getInitials(localStorage.getItem("auth_name"))}
                </span>
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => this.setState({ showModalUser: true })}
                  >
                    <i className="fa-solid fa-gear"> </i> Thông tin
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item text-danger"
                    onClick={() => this.logout()}
                  >
                    <i className="fa-solid fa-right-from-bracket"></i> Đăng xuất
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        {this.state.showModalUser ? (
          <UserEditModal
            show={this.state.showModalUser}
            onHide={() => this.setState({ showModalUser: false })}
            onSave={() => this.setState({ showModalUser: false })}
            userId={localStorage.getItem("auth_id_user")}
            isType="private"
          />
        ) : null}
      </nav>
    );
  }
}

function myParams(Component) {
  return (props) => (
    <Component navHook={useNavigate()} {...props} params={useParams()} />
  );
}

export default myParams(Navbar);
