import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../utils/helpers";

const ProductPlanExport = ({
  listQuantitative: data,
  show,
  onHide,
  classGroups,
  setSelectedClassGroups,
  selectedClassGroup,
  handleExport,
  selectedDay,
}) => {
  if (!data) {
    return "";
  }

  const renderTable = (data) => {
    let quantitative = data;
    // const costPerDays = quantitative.costPerDays || [];
    const sumTotalRegisterByIdTime = (idTime) => {
      let times = quantitative.times;
      if (!Array.isArray(times)) {
        throw new Error("Lỗi");
      }

      return times
        .filter((time) => time.id_time === idTime)
        .reduce((sum, time) => {
          const foodRegisterSum = time.foods?.reduce((foodSum, food) => {
            const groupRegisterSum = food.groups?.reduce(
              (groupSum, group) => groupSum + (group.totalRegister || 0),
              0
            );
            return foodSum + (groupRegisterSum || 0);
          }, 0);

          return sum + (foodRegisterSum || 0);
        }, 0);
    };
    // if (selectedClassGroup) {
    const filteredCostPerDays = quantitative?.costPerDays;
    // ?.filter(
    //   (c) => Number(c.id_group) === Number(selectedClassGroup.id)
    // );

    const updatedTimes = quantitative?.times?.map((time) => ({
      ...time,
      foods: time.foods?.map((f) => ({
        ...f,
        groups: f.groups
          // ?.filter((g) => Number(g.id) === Number(selectedClassGroup.id))
          ?.map((g) => ({
            ...g,
            ingredents: g.ingredents,
          })),
      })),
      // ?.filter((e) => e.groups?.length > 0),
    }));

    quantitative = {
      ...quantitative,
      costPerDays: filteredCostPerDays,
      times: updatedTimes,
    };

    // }

    return (
      <>
        <Table bordered>
          <thead>
            <tr>
              <th colSpan={7} className="text-center">
                BẢNG ĐỊNH LƯỢNG CHI TIẾT{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </th>
            </tr>
            <tr>
              <th colSpan={7}></th>
            </tr>
            <tr className="text-center">
              <th>CA</th>
              <th>TÊN MÓN</th>
              <th>SỐ PHẦN</th>
              <th>THÀNH PHẦN</th>
              <th>QUY CÁCH</th>
              <th>ĐỊNH LƯỢNG (g)</th>
              <th>KHỐI LƯỢNG (kg)</th>
            </tr>
          </thead>
          <tbody>
            {quantitative?.times?.map((timeItem, timeIndex) => {
              const countIngredientByTime = timeItem.foods?.reduce(
                (foodSum, food) => {
                  const group = food.groups[0];
                  const groupIngredientCount = group?.ingredents?.length || 0;
                  return foodSum + groupIngredientCount;
                },
                0
              );

              if (timeItem?.foods?.length === 0) {
                return (
                  <tr key={timeItem.id}>
                    <td colSpan={7}>{timeItem.time_name}</td>
                  </tr>
                );
              }

              return (
                <React.Fragment key={timeItem.id}>
                  {timeItem?.foods?.length > 0 &&
                    timeItem.foods?.map((foodItem, foodIndex) => {
                      return (
                        <React.Fragment key={foodItem.id}>
                          {foodItem.groups[0]?.ingredents?.map(
                            (ingredientItem, ingIndex) => {
                              const numberOfMeals =
                                foodItem?.groups?.reduce(
                                  (groupSum, group) =>
                                    groupSum + (group.totalRegister || 0),
                                  0
                                ) || 0;

                              const countIngredientByFood =
                                foodItem.groups[0]?.ingredents?.length || 1;

                              const qtyByUnitCook =
                                Number(ingredientItem.ing_quantity).toFixed(
                                  1
                                ) || 0;

                              const totalQtyByUnitMarket =
                                (qtyByUnitCook / ingredientItem.volume_market) *
                                numberOfMeals;

                              return (
                                <tr key={ingredientItem.id}>
                                  {foodIndex === 0 && ingIndex === 0 ? (
                                    <td rowSpan={countIngredientByTime}>
                                      {timeItem.time_name}
                                    </td>
                                  ) : (
                                    <></>
                                  )}

                                  {ingIndex === 0 ? (
                                    <>
                                      <td rowSpan={countIngredientByFood}>
                                        {foodItem.food_name}
                                      </td>
                                      <td
                                        rowSpan={countIngredientByFood}
                                        className="text-center"
                                      >
                                        <CurrencyFormat
                                          value={numberOfMeals}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalScale={0}
                                        />
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <td>{ingredientItem.ingredent_name || ""}</td>
                                  <td></td>
                                  <td className="text-end">
                                    <CurrencyFormat
                                      value={ingredientItem.ing_quantity}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                      fixedDecimalScale={true}
                                    />
                                  </td>
                                  <td className="text-end">
                                    <CurrencyFormat
                                      value={totalQtyByUnitMarket}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>

        <Table
          className="d-none"
          bordered
          id={`data-${quantitative.day_name}-${quantitative.id}`}
        >
          <thead>
            <tr>
              <th className="text-center">
                BẢNG ĐỊNH LƯỢNG CHI TIẾT{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </th>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="text-center">
              <th>CA</th>
              <th>TÊN MÓN</th>
              <th>SỐ PHẦN</th>
              <th>THÀNH PHẦN</th>
              <th>QUY CÁCH</th>
              <th>ĐỊNH LƯỢNG (g)</th>
              <th>KHỐI LƯỢNG (kg)</th>
            </tr>
          </thead>
          <tbody>
            {quantitative?.times?.map((timeItem, timeIndex) => {
              const countIngredientByTime = timeItem.foods?.reduce(
                (foodSum, food) => {
                  const group = food.groups[0];
                  const groupIngredientCount = group?.ingredents?.length || 0;
                  return foodSum + groupIngredientCount;
                },
                0
              );

              if (timeItem?.foods?.length === 0) {
                return (
                  <tr key={timeItem.id}>
                    <td colSpan={7}>{timeItem.time_name}</td>
                  </tr>
                );
              }

              return (
                <React.Fragment key={timeItem.id}>
                  {timeItem?.foods?.length > 0 &&
                    timeItem.foods?.map((foodItem, foodIndex) => {
                      return (
                        <React.Fragment key={foodItem.id}>
                          {foodItem.groups[0]?.ingredents?.map(
                            (ingredientItem, ingIndex) => {
                              const numberOfMeals =
                                foodItem?.groups?.reduce(
                                  (groupSum, group) =>
                                    groupSum + (group.totalRegister || 0),
                                  0
                                ) || 0;

                              const countIngredientByFood =
                                foodItem.groups[0]?.ingredents?.length || 1;

                              const qtyByUnitCook =
                                Number(ingredientItem.ing_quantity).toFixed(
                                  1
                                ) || 0;

                              const totalQtyByUnitMarket =
                                (qtyByUnitCook / ingredientItem.volume_cook) *
                                numberOfMeals;

                              return (
                                <tr key={ingredientItem.id}>
                                  {foodIndex === 0 && ingIndex === 0 ? (
                                    <td>{timeItem.time_name}</td>
                                  ) : (
                                    <td>empty</td>
                                  )}

                                  {ingIndex === 0 ? (
                                    <>
                                      <td>{foodItem.food_name}</td>
                                      <td>
                                        <CurrencyFormat
                                          value={numberOfMeals}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalScale={0}
                                        />
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>empty</td>
                                      <td>empty</td>
                                    </>
                                  )}
                                  <td>{ingredientItem.ingredent_name || ""}</td>
                                  <td>empty</td>
                                  <td className="text-end">
                                    <CurrencyFormat
                                      value={ingredientItem.ing_quantity}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                      fixedDecimalScale={true}
                                    />
                                  </td>
                                  <td className="text-end">
                                    <CurrencyFormat
                                      value={totalQtyByUnitMarket}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <>
      {data
        ?.filter((e) => Number(e.id_day) === Number(selectedDay))
        ?.map((item) => {
          return renderTable(item);
        })}
    </>
  );
};

export default ProductPlanExport;
