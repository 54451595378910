import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
  Alert,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import CurrencyFormat from "react-currency-format";
import SettingExpenditureNorms from "./SettingExpenditureNorms";

class DeadLineRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {
        hours: 0,
        minutes: 0,
      },
      hour_: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      minute_: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 58, 59,
      ],
      timeOfDays: [],
      categories: [],
      settings: {
        timesId: [],
        categoriesId: [],
      },
      mealsPrice: {},
    };
  }

  componentDidMount() {
    document.title = "Cài đặt | Đăng ký suất ăn";
    this.getDeadline();
    this.getTimeOfDays();
    this.getCategories();
    this.getSettingsMealRegistration();
    // this.getSetting(Math.floor(Date.now() / 1000));
    this.getSettingMealPrice(Math.floor(Date.now() / 1000));
  }

  getDeadline() {
    api
      .get(`/get-deadline-for-menu`)
      .then((res) => {
        this.setState({
          time: res.data.data?.payload || {
            hours: 0,
            minutes: 0,
          },
        });
      })
      .catch((error) => {
        this.setState({
          time: {
            hours: 0,
            minutes: 0,
          },
        });
      });
  }

  getTimeOfDays() {
    api
      .get(`/getTimeOfDays`)
      .then((res) => {
        this.setState({
          timeOfDays: res.data?.times || [],
        });
      })
      .catch((error) => {});
  }

  getCategories() {
    api
      .get(`/category-show`)
      .then((res) => {
        this.setState({
          categories: res.data?.categories || [],
        });
      })
      .catch((error) => {});
  }

  getSettingsMealRegistration() {
    api
      .get(`/get-settings-meal-registration`)
      .then((res) => {
        if (res.data?.data?.payload)
          this.setState({
            settings: res.data.data.payload,
          });
      })
      .catch((error) => {});
  }

  getSettingMealPrice(date) {
    api
      .get(`/get-kitchen-setting-meal-prices?date=${date}`)
      .then((res) => {
        this.setState({
          mealsPrice: res.data.data,
        });
      })
      .catch((error) => {});
  }

  updateSubmit = (e) => {
    e.preventDefault();
    let data = {
      deadline: {
        hours: this.state.time?.hours || 0,
        minutes: this.state.time?.minutes || 0, // from 0 -60, required
      },
    };
    swal({
      title: "Bạn muốn cập nhật thời gian đăng ký suất ăn?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(`set-deadline-for-menu`, data)
            .then((res) => {
              toast(res.data.messages || "Thành công", { type: "success" });
            })
            .catch((error) => {
              toast(`Lỗi `, {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((error) => {});
  };

  updateSettings = (e) => {
    e.preventDefault();
    let data = this.state.settings;
    swal({
      title: "Bạn muốn cập nhật?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(`settings-meal-registration`, data)
            .then((res) => {
              toast(res.data.messages || "Thành công", { type: "success" });
            })
            .catch((error) => {
              toast(`Lỗi `, {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((error) => {});
  };

  renderSetting() {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-clock"></i> Thời gian chốt đăng ký suất ăn
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.updateSubmit}>
          <Card.Body>
            <Alert variant="primary pb-0">
              <strong>*Lưu ý:</strong>
              <ul>
                <li>Phải đăng ký suất ăn ngày hôm sau trước Thời gian chốt.</li>
              </ul>
            </Alert>

            <Row>
              <Col md={3}>
                <Form.Floating>
                  <Form.Select
                    name="time_hour"
                    className=""
                    required
                    value={this.state.time?.hours}
                    onChange={(e) => {
                      this.setState({
                        time: { ...this.state.time, hours: e.target.value },
                      });
                    }}
                  >
                    {this.state.hour_?.map((e) => {
                      return (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <label>Giờ</label>
                </Form.Floating>
              </Col>
              <Col md={3}>
                <Form.Floating>
                  <Form.Select
                    name="time_hour"
                    required
                    value={this.state.time?.minutes}
                    onChange={(e) => {
                      this.setState({
                        time: { ...this.state.time, minutes: e.target.value },
                      });
                    }}
                  >
                    {this.state.minute_?.map((e) => {
                      return (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <label>Phút</label>
                </Form.Floating>
              </Col>
            </Row>
          </Card.Body>

          <Card.Footer className="text-end">
            <Button size="sm" variant="success" type="submit">
              <i className="fas fa-check"></i> Cập nhật
            </Button>
          </Card.Footer>
        </Form>
      </>
    );
  }

  renderSettingMealRegistration() {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-pencil"></i> Đăng ký suất ăn
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.updateSettings}>
          <Card.Body>
            <h5>Buối có ràng buộc món chính và món phụ</h5>
            <Alert variant="primary pb-0">
              <strong>*Lưu ý:</strong>
              <ul>
                <li>
                  Buổi được chọn sẽ ràng buộc số lượng đăng ký của món phụ bằng
                  tổng số lượng đăng ký của món chính.
                </li>
                <li>
                  Buổi không được chọn sẽ không ràng buộc số lượng giữa các món.
                </li>
              </ul>
            </Alert>
            <Row>
              {this.state.timeOfDays
                ?.filter((time) => time.time_name_now !== null)
                ?.map((time, timeIndex) => {
                  const isChecked = this.state.settings?.timesId?.includes(
                    time.id
                  );

                  return (
                    <Col key={`time-key-${timeIndex}`}>
                      <Form.Check
                        type="switch"
                        checked={isChecked}
                        onClick={(e) => {
                          let newTimesId =
                            [...this.state.settings?.timesId] || [];
                          if (isChecked) {
                            newTimesId = newTimesId.filter(
                              (id) => id !== time.id
                            );
                          } else {
                            newTimesId.push(time.id);
                          }
                          this.setState({
                            settings: {
                              ...this.state.settings,
                              timesId: newTimesId,
                            },
                          });
                        }}
                        label={time.time_name_now || time.time_name}
                      />
                    </Col>
                  );
                })}
            </Row>

            <hr />
            <h5>Danh mục món chính</h5>

            <Row>
              {this.state.categories?.map((category, categoryIndex) => {
                const isChecked = this.state.settings?.categoriesId?.includes(
                  category.id
                );
                return (
                  <Col md={4} key={`category-key-${categoryIndex}`}>
                    <Form.Check
                      type="switch"
                      checked={isChecked}
                      onClick={(e) => {
                        let newCategorysId =
                          [...this.state.settings?.categoriesId] || [];
                        if (isChecked) {
                          newCategorysId = newCategorysId.filter(
                            (id) => id !== category.id
                          );
                        } else {
                          newCategorysId.push(category.id);
                        }
                        this.setState({
                          settings: {
                            ...this.state.settings,
                            categoriesId: newCategorysId,
                          },
                        });
                      }}
                      label={category.category_name}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
          <Card.Footer className="text-end">
            <Button size="sm" variant="success" type="submit">
              <i className="fas fa-check"></i> Cập nhật
            </Button>
          </Card.Footer>
        </Form>
      </>
    );
  }

  handleServiceChange = (name, value, indexService) => {
    const { services } = this.state;
    const updatedService = {
      ...services[indexService],
      [name]: value,
    };

    // Cập nhật dịch vụ tại chỉ số `indexService`
    const updatedServices = [
      ...services.slice(0, indexService),
      updatedService,
      ...services.slice(indexService + 1),
    ];

    // Kiểm tra nếu tất cả các dịch vụ đã có tên, thêm một hàng mới
    if (
      updatedService.serviceName.trim() !== "" &&
      indexService === services.length - 1
    ) {
      updatedServices.push({ id: "", serviceName: "" }); // Hàng mới với dữ liệu trống
    }

    // Cập nhật lại state
    this.setState({ services: updatedServices });
  };

  handleItemNameChange = (indexGroup, indexItem, newName, times) => {
    this.setState((prevState) => {
      const updatedGroups = [...prevState.mealsPrice.groups];

      // Cập nhật tên dịch vụ
      updatedGroups[indexGroup].items[indexItem].item_name = newName;

      // Kiểm tra xem tất cả các item hiện tại đã có dữ liệu
      const allFilled = updatedGroups[indexGroup].items.every(
        (item) => item.item_name.trim() !== ""
      );

      // Nếu tất cả đã có dữ liệu, thêm một hàng trống
      if (allFilled) {
        updatedGroups[indexGroup].items.push({
          id_item: Date.now(), // Hoặc bất kỳ giá trị `id` nào phù hợp
          item_name: "",
          times: times?.map((time) => ({
            id_time: time.id_time,
            time_name: time.time_name,
            meal_price: "",
          })), // Thêm các giá trị mặc định nếu cần
        });
      }

      return {
        mealsPrice: {
          ...prevState.mealsPrice,
          groups: updatedGroups,
        },
      };
    });
  };

  handleDeleteItem = (indexGroup, indexItem) => {
    this.setState((prevState) => {
      const updatedGroups = [...prevState.mealsPrice.groups];

      // Xóa dòng từ danh sách `items`
      updatedGroups[indexGroup].items.splice(indexItem, 1);

      return {
        mealsPrice: {
          ...prevState.mealsPrice,
          groups: updatedGroups,
        },
      };
    });
  };

  handleMealPriceChange = (indexGroup, indexItem, idTime, newPrice) => {
    this.setState((prevState) => {
      const updatedGroups = [...prevState.mealsPrice.groups];
      const times = updatedGroups[indexGroup].items[indexItem].times.map(
        (time) =>
          time.id_time === idTime ? { ...time, meal_price: newPrice } : time
      );
      updatedGroups[indexGroup].items[indexItem].times = times;

      return {
        mealsPrice: {
          ...prevState.mealsPrice,
          groups: updatedGroups,
        },
      };
    });
  };

  handleSubmitSaveMealsPrice = () => {
    swal({
      title: "Bạn chắc muốn cập nhật?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`set-kitchen-setting-meal-prices`, this.state.mealsPrice, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            toast.success(res.data.messages);

            this.getSettingMealPrice(Math.floor(Date.now() / 1000));
          })
          .catch((error) => {
            toast.error("Đã xảy ra lỗi. Vui lòng thử lại sau.");
          });
      }
    });
  };

  renderService() {
    return (
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>Chi phí bữa ăn</Card.Title>
        </Card.Header>

        <Form onSubmit={this.submitSetService}>
          <Card.Body>
            <Tabs defaultActiveKey={0} className="mb-1">
              {this.state.mealsPrice?.groups?.map((group, indexGroup) => {
                const times = this.state.mealsPrice?.groups?.flatMap((group) =>
                  group.items.flatMap((item) => item.times)
                );

                const uniqueTimes = times.filter(
                  (time, index, self) =>
                    index === self.findIndex((t) => t.id_time === time.id_time)
                );

                return (
                  <Tab
                    key={indexGroup}
                    title={group.group_name}
                    eventKey={indexGroup}
                  >
                    {
                      <div style={{ overflowX: "scroll" }}>
                        <Table size="lg" bordered hover>
                          <thead>
                            <tr className="align-middle text-center">
                              <th className="text-center">STT</th>
                              <th>Chi phí</th>
                              {uniqueTimes?.map((time, indexTime) => {
                                return (
                                  <th style={{ width: "100px" }}>
                                    {time.time_name}
                                  </th>
                                );
                              })}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.mealsPrice?.groups[
                              indexGroup
                            ].items.map((item, indexItem) => {
                              return (
                                <tr key={`item-key-${indexItem}`}>
                                  <td className="text-center">
                                    {indexItem + 1}
                                  </td>
                                  <td>
                                    <Form.Control
                                      value={item.item_name}
                                      onChange={(e) =>
                                        this.handleItemNameChange(
                                          indexGroup,
                                          indexItem,
                                          e.target.value,
                                          uniqueTimes
                                        )
                                      }
                                    />
                                  </td>
                                  {uniqueTimes.map((time, indexTime) => {
                                    return (
                                      <td
                                        className={`text-center ${
                                          item.times.find(
                                            (t) => t.id_time === time.id_time
                                          )
                                            ? "text-success"
                                            : ""
                                        }`}
                                      >
                                        <CurrencyFormat
                                          className="form-control text-end"
                                          value={
                                            item.times.find(
                                              (t) => t.id_time === time.id_time
                                            )?.meal_price || ""
                                          }
                                          displayType={"input"}
                                          thousandSeparator={true}
                                          decimalScale={0}
                                          onValueChange={(values) => {
                                            const { value } = values;
                                            this.handleMealPriceChange(
                                              indexGroup,
                                              indexItem,
                                              time.id_time,
                                              value
                                            );
                                          }}
                                          disabled={item.item_name === ""}
                                        />
                                      </td>
                                    );
                                  })}
                                  <td className="align-content-center">
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        this.handleDeleteItem(
                                          indexGroup,
                                          indexItem
                                        )
                                      }
                                      disabled={item.item_name === ""}
                                    >
                                      Xóa
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    }
                  </Tab>
                );
              })}
            </Tabs>
          </Card.Body>

          <Card.Footer className="text-end">
            <Button
              size="sm"
              variant="success"
              type="button"
              onClick={this.handleSubmitSaveMealsPrice}
            >
              <i className="fas fa-check"></i> Cập nhật
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Đăng ký suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={6}>
              <Card className="mb-4">{this.renderSetting()}</Card>

              <Card className="mb-4">
                {this.renderSettingMealRegistration()}
              </Card>
            </Col>

            <Col md={6}>
              <SettingExpenditureNorms />
              {this.renderService()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DeadLineRegister;
