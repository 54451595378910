import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Table } from "react-bootstrap";
import QRCode from "react-qr-code";
import {
  priceFormatterNoDecimal,
  dateFormatter,
  capitalizeFirstLetter,
  soThanhChu,
  extractAfterNumbers,
  toVietnamese,
} from "../utils/helpers";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";

const ComponentToPrint = ({ bank, item, schoolInfo, qrCode, providerBank }) => {
  const base64Image = `data:image/png;base64,${qrCode}`;
  return (
    <div
      style={{
        fontFamily: "Times New Roman, Times, serif",
        color: "#000",
        pageBreakAfter: "always",
      }}
      className="hidden-print"
    >
      <div className="row">
        <div className="col-10">
          <div>{schoolInfo?.school_name}</div>
          <div>{schoolInfo?.school_address}.</div>
          <div style={{ color: "blue" }}>
            Điện thoại liên hệ: {schoolInfo?.school_phone}
          </div>
        </div>
        <div className="col">
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 512,
              width: "100%",
            }}
          >
            {providerBank === "HDB_API_KEY"
              ? qrCode && (
                  <img
                    src={base64Image}
                    alt="QR Code"
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  />
                )
              : qrCode && (
                  <QRCode
                    size={512}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrCode}
                    viewBox="0 0 256 256"
                  />
                )}
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            fontSize: "16px",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          BIỂU PHÍ HỌC TẬP
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "13px",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          Ngày: {dateFormatter(new Date())}
        </div>
        <div style={{ height: "60px" }}>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              // justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            TÊN: {item?.student?.student_name}
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              // justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            LỚP: {item?.student?.classes[0]?.class_name}
          </div>
        </div>
        <Table bordered style={{ border: "1px solid" }}>
          <thead>
            <tr className="text-center align-middle ">
              <th>
                <span className="text-danger">STT</span>
              </th>
              <th>
                <span className="text-danger">DIỄN GIẢI</span>
              </th>
              <th>
                <span className="text-danger"> ĐVT</span>
              </th>
              <th>
                <span className="text-danger">SL</span>
              </th>

              <th>
                <span className="text-danger"> ĐƠN GIÁ</span>
              </th>
              <th>
                <span className="text-danger"> THÀNH TIỀN</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {item?.details
              ?.filter((e) => e.bill_detailable !== "DiscountRefunded")
              ?.map((detail, index) => (
                <tr key={index} className="align-middle">
                  <td className="text-center align-middle">{index + 1}</td>
                  <td>{detail?.content}</td>
                  <td>
                    {detail.bill_detailable === "FoodMoney" ||
                    detail.bill_detailable === "FoodRefunded" ||
                    detail.bill_detailable === "NotEatRefunded" ||
                    detail.bill_detailable === "HolidayRefunded"
                      ? "Ngày"
                      : detail.bill_detailable === "MonthlyTuition"
                      ? "Tháng"
                      : detail.bill_detailable === "AnnualTuition"
                      ? "Năm"
                      : detail.bill_detailable === "ExtraTuition"
                      ? "Khóa"
                      : ""}
                  </td>
                  <td className="text-end align-middle">
                    {priceFormatterNoDecimal.format(detail?.quantity)}
                  </td>
                  <td className="text-end align-middle">
                    {priceFormatterNoDecimal.format(detail?.price)}
                  </td>
                  <td className="text-end align-middle">
                    {priceFormatterNoDecimal.format(
                      detail.bill_detailable === "HolidayRefunded" ||
                        detail.bill_detailable === "NotEatRefunded" ||
                        detail.bill_detailable === "FoodRefunded" ||
                        detail.bill_detailable === "DiscountRefunded"
                        ? 0 - detail?.sub_total
                        : detail?.sub_total
                    )}
                  </td>
                </tr>
              ))}
            <tr>
              <th colSpan={5} className="text-center">
                Tổng cộng
              </th>
              <th className="text-end">
                {priceFormatterNoDecimal.format(
                  // item?.trans_amount
                  item?.details
                    ?.filter((e) => e.bill_detailable !== "DiscountRefunded")
                    .reduce((acc, e) => {
                      const quantity = parseFloat(e.quantity || 0);
                      const price = parseFloat(e.price || 0);
                      const itemTotal = quantity * price;

                      if (
                        e.bill_detailable === "HolidayRefunded" ||
                        e.bill_detailable === "FoodRefunded" ||
                        e.bill_detailable === "NotEatRefunded" ||
                        e.bill_detailable === "DiscountRefunded"
                      ) {
                        return acc - itemTotal; // Trừ đi nếu là loại Refund
                      } else {
                        return acc + itemTotal; // Cộng vào nếu không phải Refund
                      }
                    }, 0)
                )}
              </th>
            </tr>
            {item?.details
              ?.filter((e) => e.bill_detailable === "DiscountRefunded")
              ?.map((detail, index) => (
                <tr key={index} className="align-middle">
                  <td className="text-center align-middle">{index + 1}</td>
                  <td>{detail?.content}</td>
                  <td></td>
                  <td className="text-end align-middle">
                    {priceFormatterNoDecimal.format(detail?.quantity)}
                  </td>
                  <td className="text-end align-middle">
                    {priceFormatterNoDecimal.format(detail?.price)}
                  </td>

                  <td className="text-end align-middle">
                    {priceFormatterNoDecimal.format(
                      detail.bill_detailable === "HolidayRefunded" ||
                        detail.bill_detailable === "NotEatRefunded" ||
                        detail.bill_detailable === "FoodRefunded" ||
                        detail.bill_detailable === "DiscountRefunded"
                        ? 0 - detail?.sub_total
                        : detail?.sub_total
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={5} className="text-center">
                <span className="text-danger">Số tiền phụ huynh đóng</span>
              </th>
              <th className="text-end">
                <span className="text-danger">
                  {priceFormatterNoDecimal.format(item?.trans_amount)}
                </span>
              </th>
            </tr>
          </tfoot>
        </Table>
        <div
          style={{
            height: "20px",
            marginTop: "-10px",
            fontStyle: "italic",
            fontWeight: "inherit !important",
          }}
        >
          <span>
            Số tiền bằng chữ:{" "}
            {capitalizeFirstLetter(toVietnamese(item?.trans_amount || 0))}.
          </span>
        </div>
        <div style={{ height: "10px" }}></div>
        <div>Ghi chú: {item.bill_notes}</div>
        <div>
          Quý phụ huynh có thể liên hệ trực tiếp đến phòng kế toán của trường để
          đóng học phí hoặc chuyển tiền vào
        </div>
        <div className="ms-3 fw-bold">
          <div>- Tên tài khoản: {bank?.owner}</div>
          <div>- Số tài khoản: {bank?.account}</div>
          <div>- Tại ngân hàng: {bank?.branch}</div>
          {bank?.notes ? <div>- {bank?.notes}</div> : <></>}
        </div>
      </div>
    </div>
  );
};

const PrintMultipleButton2 = ({
  bank,
  schoolInfo,
  item,
  choiceClass,
  showQr,
  providerBank,
}) => {
  const componentRef = useRef();
  const [qrCode, setQrCode] = useState("");

  const printAction = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Danh sách phiếu thu " + choiceClass?.label,
  });

  const handlePrint = async () => {
    if (choiceClass?.value < 1) {
      toast("Vui lòng chọn lớp để xuất", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }

    if (item?.length < 1) {
      toast("Vui lòng chọn phiếu thu", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }
    if (providerBank && item?.length > 1) {
      toast("Chỉ được chọn 1 hóa đơn khi xuất kèm QR", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    if (!providerBank) {
      printAction();

      setQrCode("");
      return;
    }

    try {
      const data = {
        billcode: item[0]?.billcode,
      };

      const response = await api({
        url: `/generate-qr-payment?method=${providerBank}`,
        method: "post",
        data,
        headers: { "Content-Type": "application/json" },
      });

      const resData = response.data?.data;

      if (providerBank === "HDB_API_KEY") {
        if (resData?.data?.data?.qrCode) {
          setQrCode(resData.data.data.qrCode);
        } else {
          toast.error("Vui lòng kiểm tra lại thông tin");
          return;
        }
      } else {
        if (resData?.status) {
          setQrCode(resData.data?.data);
        } else {
          toast.error("Vui lòng kiểm tra lại thông tin");
          return;
        }
      }
      setTimeout(() => {
        printAction();
      }, 0);
    } catch (err) {
      console.error(err);
      toast.error(err.message || "Hệ thống xảy ra lỗi");
    }
  };

  return (
    <span>
      <div ref={componentRef}>
        {item.map((item) => (
          <ComponentToPrint
            key={item.id}
            item={item}
            schoolInfo={schoolInfo}
            bank={bank}
            providerBank={providerBank}
            qrCode={providerBank ? qrCode : ""}
          />
        ))}
      </div>
      <Button
        variant="danger mt-2 mt-md-0 me-2"
        // size="sm"
        onClick={handlePrint}
        hidden={choiceClass?.value < 1}
        title={
          choiceClass?.value < 1
            ? "Vui lòng chọn lớp để xuất"
            : item?.length < 1
            ? "Chọn phiếu thu để xuất"
            : "Xuất phiếu thu"
        }
      >
        <i className="fa-solid fa-file-pdf"></i> Xuất PDF 2
      </Button>
    </span>
  );
};

export default PrintMultipleButton2;
