import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import {
  dateFormatter,
  changeEmpty,
  capitalizeFirstLetter,
  soThanhChu,
  extractAfterNumbers,
} from "../../utils/helpers";

const MarketListTableSheets = ({ voucher, ingredients }) => {
  if (!voucher) {
    return "";
  }

  return (
    <div
      style={{ overflowX: "scroll" }}
      className="table-responsive-600 d-none"
    >
      {ingredients?.map((supplier, supplierIndex) => {
        if (supplier?.ingredients?.length > 0)
          return (
            <Table
              key={`supplier-key-${supplierIndex}`}
              bordered
              id={`supplier-${supplier.supplier_name}-${supplier.id}`}
              // className="d-none"
            >
              <thead>
                <tr>
                  <th>PHIẾU ĐẶT HÀNG</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th> Nhà cung cấp: {supplier.supplier_name}</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th> Số HĐ:</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th>
                    Ngày giao hàng:{" "}
                    {dateFormatter(new Date(voucher.menu_date * 1000))}
                  </th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>
                    Ngày đặt hàng:{" "}
                    {dateFormatter(new Date(voucher.menu_date * 1000 - 86400))}
                  </th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th> Người liên hệ: Chị Tân</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>Điện thoại: 0984676397 </th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th>
                    Địa điểm giao hàng: Trường TiH, THCS, THPT Ngô Thời Nhiệm
                    Bình Dương
                  </th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th>STT</th>
                  <th>MH</th>
                  <th>TÊN HÀNG</th>
                  <th>ĐVT</th>
                  <th>QUY CÁCH</th>
                  <th>SỐ LƯỢNG</th>
                  <th>ĐƠN GIÁ</th>
                  <th>THÀNH TIỀN</th>
                  <th>GHI CHÚ</th>
                </tr>
              </thead>
              <tbody>
                {supplier?.ingredients?.map((ingredient, indexIngrdient) => {
                  return (
                    <tr>
                      <td>{indexIngrdient + 1}</td>
                      <td>{changeEmpty(ingredient.ingredent_code)}</td>
                      <td>{changeEmpty(ingredient.ingredent_name)}</td>
                      <td>{changeEmpty(ingredient.unit_market_name)}</td>
                      <td>{changeEmpty()}</td>
                      <td>
                        <CurrencyFormat
                          value={parseFloat(ingredient.ing_quantity || 0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          isNumericString
                          decimalScale={2}
                        />
                      </td>
                      {/* <td>
                        <CurrencyFormat
                          value={parseFloat(ingredient.ing_price || 0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          isNumericString
                          decimalScale={0}
                        />
                      </td> 
                      <td>
                        <CurrencyFormat
                          value={
                            parseFloat(ingredient.ing_quantity) *
                            parseFloat(ingredient.ing_price)
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          isNumericString
                          decimalScale={0}
                        />
                      </td>
                      */}
                      <td>empty</td>
                      <td>empty</td>

                      <td>
                        {changeEmpty(
                          `${
                            Number(ingredient.is_fresh) === 1
                              ? ingredient.time_name || ""
                              : ""
                          } ${ingredient.vou_notes || ""}`.trim()
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td>Tổng cộng:</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {/* <CurrencyFormat
                      value={parseFloat(
                        supplier?.ingredients?.reduce(
                          (sum, account) =>
                            sum +
                            parseFloat(account.ing_price) *
                              parseFloat(account.ing_quantity),
                          0
                        )
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      className="text-end"
                      isNumericString
                      decimalScale={0}
                    /> */}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Tổng số tiền bằng chữ:{" "}
                    {/* {capitalizeFirstLetter(
                      extractAfterNumbers(
                        soThanhChu(
                          parseInt(
                            supplier?.ingredients?.reduce(
                              (sum, account) =>
                                sum +
                                parseFloat(account.ing_price) *
                                  parseFloat(account.ing_quantity),
                              0
                            ) || 0
                          )
                        )
                      )
                    )} */}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>NGƯỜI NHẬN HÀNG</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>NGƯỜI GIAO HÀNG</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          );
      })}
    </div>
  );
};

export default MarketListTableSheets;
